@tailwind base;
@tailwind components;
@tailwind utilities;

body, html {
  height: 100%;
}

/* using em instead of rem so that it works in jupyter widget, which is resetting base font size to 10px */
.f6-rel { font-size: 0.875em; }
.f7-rel { font-size: 0.75em; }

/* jupyter notebook is setting .collapse class to display: none in component-animations.less, so we can't use tachyons class */
.table-collapse { border-collapse: collapse; border-spacing: 0; }

.w2-5 { width: 2.5rem; }
.h2-5 { height: 2.5rem; }
.w6 { width: 24rem; }
.w7 { width: 48rem; }

.flex-0 { flex: 0; }
.flex-1 { flex: 1; }
.flex-2 { flex: 2; }
.flex-3 { flex: 3; }
.flex-4 { flex: 4; }

.bg-off-white {
  background-color: #fcfcfc;
}

.shadow {
  box-sizing: border-box;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
}

.measure-xwide {
  max-width: 48em;
}

.no-select {
  -webkit-user-select: none; /* chrome/safari */
  -moz-user-select: none; /* firefox */
  -ms-user-select: none; /* ie10+ */
  -o-user-select: none; /* modern browsers */
  user-select: none; /* modern browsers */
}

/* this is namespaced to avoid clashing with 'btn' class present on jupyter notebook buttons */
.markers-widget .btn { font-size: 1em; }

.link-primary {
  color: #0087f0;
}
.bg-primary {
  background: #0087f0;
}

.btn-primary {
  background: #0087f0;
  border-color: #35A7FF;
}
  .btn-primary.inverted {
    background: none;
    color: #0087f0;
  }

.btn-primary:hover,
.btn-primary:focus {
  background-color: #249fff;
}
  .btn-primary.inverted:hover,
  .btn-primary.inverted:focus {
    background-color: #f0f8ff;
  }

.btn-primary.disabled {
  background-color: #acdbff;
  border-color: #79c4ff;
  cursor: not-allowed;
}
  .btn-primary.inverted.disabled {
    background-color: #fff;
    border-color: #9bd3ff;
    color: #79c4ff;
    cursor: not-allowed;
  }

.btn-secondary {
  background: #ff5964;
  border-color: #ff7b84;
}
  .btn-secondary.inverted {
    background: none;
    color: #ff5964;
  }
  .btn-secondary.disabled {
    background-color: #fcc;
    border-color: #f99;
    cursor: not-allowed;
  }

.btn-secondary:hover,
.btn-secondary:focus {
  background-color: #ff7b84;
}
  .btn-secondary.inverted:hover,
  .btn-secondary.inverted:focus {
    background-color: #fff2f3;
  }

.btn-tertiary {
  background: #eee;
  color: #777;
  border-color: #ccc;
}

.btn-tertiary:hover,
.btn-tertiary:focus {
  background-color: #ddd;
}

.btn-tertiary.disabled {
  color: #bbb;
  cursor: not-allowed;
}

.primary-color {
  color: #0087f0;
}
.bg-primary-color {
  background-color: #0087f0;
}
.b--primary-color {
  border-color: #0087f0 !important;
}
.error-color {
  color: #ff5964;
}
.b--error-color {
  border-color: #ff5964;
}
  .bg-washed-error-color {
    background-color: #fff2f3;
  }

.bg-lighterest-blue {
  background-color: #def2ff;
}

.green-color {
  color: #72b579;
}

.ml-n2 {
  margin-left: -4px;
}

.fade-in {
  opacity: 1;
  transition: opacity 60ms ease-in;
}

.fade-out {
  opacity: 0;
  transition: opacity 500ms ease-out;
}

.circle-trail {
  /* cribbing liberally from https://stackoverflow.com/questions/19642308/creating-css3-circles-connected-by-lines */
  position: relative;
}
  .circle-trail:first-child::before {
    display: none;
  }
  .circle-trail::before {
    content: ' ';
    position: absolute;
    top: .9em;
    left: -7.25rem;
    width: 9rem;
    height: 6px;
    border-radius: 3px;
    background: #ccc;
    z-index: -1;
  }
  .circle-trail.active::before {
    background: #357EDD; /* blue from tachyons */
  }
  .circle-trail.complete::before {
    background: #9EEBCF; /* light-green from tachyons */
  }
  .circle-trail .label {
    line-height: 2rem;
  }

@media screen and (min-width: 60em) {
  .w8-l {
    width: 64rem;
  }
}


@media screen and (min-height: 60em) {
  .items-start-lh {
    align-items: flex-start;
  }

  .pt6-lh {
    padding-top: 8rem;
  }
}

.not-allowed {
  cursor: not-allowed;
}

/* prep work for pipeline component overflow-x scroll-abiliity */
.overflow-x-scroll::-webkit-scrollbar {
  display: none;
}